<template>
  <div>
    <header-main></header-main>
    <div class="container-404">
      <div>
        <h2>Oops! No encuentro esa página.</h2>
        <h1>404</h1>
        <p>Definitivamente no puedo encontrar la página que estás buscando.</p>
        <router-link to="/">Volver al Inicio</router-link>
      </div>
    </div>
    <footer-main></footer-main>
  </div>
</template>

<script>
import HeaderMain from "@/components/Header.vue";

import FooterMain from "@/components/Footer.vue";

export default {
  name: "PrivacyPolicy",
  components: {
    HeaderMain,
    FooterMain,
  },
  data() {
    return {};
  },
  metaInfo() {
    return {
      title: "Appears |",
      titleTemplate:
        "%s Agencia Digital | Estamos reestructurando nuestro sitio web.",
      meta: [
        {
          name: "description",
          content:
            "Somos un equipo preparado para desarrollar tu idea con el objetivo de llevarla a un nivel superior, descubrirás el impacto de la tecnología en esta era digital.",
        },
        {
          name: "keywords",
          content:
            "Appears, paginas web, paginas web villavicencio, sitio web, seo, sem, aplicaciones a la medida, software, google ads, facebook ads, publicidad en google, publicidad en facebook, publicidad en instagram, web, website, diseño web, desarrollo web",
        },
        {
          name: "author",
          content: "Appears",
        },
        {
          name: "robots",
          content: "Index, Follow",
        },
        {
          rel: "canonical",
          href: "https://appears.com.co/",
        },
        // Tags twitter
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:site",
          content: "@appears",
        },
        {
          name: "twitter:creator",
          content: "@appears",
        },
        {
          name: "twitter:title",
          content:
            "Appears | Agencia Digital | Estamos reestructurando nuestro sitio web.",
        },
        {
          name: "twitter:description",
          content:
            "Somos un equipo preparado para desarrollar tu idea con el objetivo de llevarla a un nivel superior, descubrirás el impacto de la tecnología en esta era digital.",
        },
        {
          name: "twitter:image",
          content: "https://appears.com.co/statics/img/img-str.png",
        },
        // Tags facebook
        {
          property: "og:locale",
          content: "es_CO",
        },
        {
          property: "og:site_name",
          content: "Appears",
        },
        {
          property: "og:url",
          content: "https://appears.com.co",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:title",
          content:
            "Appears | Agencia Digital | Estamos reestructurando nuestro sitio web.",
        },
        {
          property: "og:description",
          content:
            "Somos un equipo preparado para desarrollar tu idea con el objetivo de llevarla a un nivel superior, descubrirás el impacto de la tecnología en esta era digital.",
        },
        {
          property: "og:image",
          content: "https://appears.com.co/statics/img/img-str.png",
        },
        {
          property: "og:image:secure_url",
          content: "https://appears.com.co/statics/img/img-str.png",
        },
        {
          property: "og:image:width",
          content: "705",
        },
        {
          property: "og:image:height",
          content: "427",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.container-404 {
  display: grid;
  align-items: center;
  width: 100%;
  /* padding: 100px 0; */
  height: 60vh;
  text-align: center;
}

.container-404 h1 {
  margin: 0;
  font-family: arial;
  font-size: 160px;
  font-weight: 900;
  -webkit-text-stroke: 5px var(--primary);
  -webkit-text-fill-color: var(--secondary);
  letter-spacing: 20px;
  line-height: 1;
}

.container-404 a {
  background: var(--secondary);
  text-decoration: none;
  color: var(--white);
  padding: 12px 24px;
  display: inline-block;
  border-radius: 25px;
  font-size: 14px;
  text-transform: uppercase;
  transition: 0.4s;
}

.container-404 a:hover {
  background-color: var(--primary);
}

@media screen and(max-width: 400px) {
  .container-404 h1 {
    font-size: 100px;
  }
}
</style>
